import { render, staticRenderFns } from "./AppCatalog.vue?vue&type=template&id=4ab74046&scoped=true&"
import script from "./AppCatalog.vue?vue&type=script&lang=js&"
export * from "./AppCatalog.vue?vue&type=script&lang=js&"
import style0 from "./AppCatalog.vue?vue&type=style&index=0&id=4ab74046&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ab74046",
  null
  
)

export default component.exports